<template>
  <main>
    <navbar></navbar>

    <section class="hero is-ligth is-fullheight-with-navbar">
      <div class="hero-body">
        <router-view />
      </div>
    </section>
  </main>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Nav/Navbar.vue'

@Component({
  components: {
    Navbar,
  },
})
export default class SingleLayout extends Vue {
  /*  mounted() {
    console.log('SingleLayout')
  } */
}
</script>
